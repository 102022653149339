
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onUpdated,
  computed,
} from "vue";
import { MBDatatable } from "magicbean-saas-common";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiMerchantCreditNotes } from "@/core/api";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  commonPreviewFile,
  formatDate,
  setModuleBCN,
  substringFileName,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { checkPermissionFlag } from "@/directives/permission";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import { InvoiceStatus } from "@/core/directive/type/orderRequest";
import { getMerchantOrderInvoiceStatus } from "@/core/directive/function/orderRequest";
import ImportModel from "./ImportModel.vue";
import UpdateInvoice from "./UpdateInvoice.vue";
import { MerchantCreditNotesTable } from "@/core/directive/interface/merchantCreditNotes";
import { getDataVersion } from "@/core/directive/function/merchantCreditNotes";

export default defineComponent({
  name: "merchant-credit-notes",
  components: {
    MBDatatable,
    PermissionCommon,
    ImportModel,
    UpdateInvoice,
  },
  setup() {
    let childWin;
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const filterRef = ref();
    const tableData = ref<Array<MerchantCreditNotesTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const disabledExportLastWeek = ref(false);
    const disabledExportLastMonth = ref(false);
    const checkedInvoice = ref<Array<number>>([]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const tableHeader = ref([
      {
        name: t("orderRequest.invoiceDate"),
        key: "invoice_date",
        sortable: false,
      },
      {
        name: t("orderRequest.invoiceNumber"),
        key: "invoice_number",
        sortable: false,
      },
      {
        name: t("orderRequest.externalOrderNumber"),
        key: "external_order_number",
        sortable: false,
      },
      {
        name: t("common.fileName"),
        key: "invoice_file",
        sortable: false,
      },
      {
        name: t("common.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("orderRequest.total"),
        key: "total_amount",
        sortable: false,
        align: "right",
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      filter_date: "invoice_date",
      invoiceId: "",
    });

    const setOtherFilter = computed(() => {
      return [
        {
          field: "data_version",
          value: getDataVersion(route),
          condition: "eq",
        },
      ];
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
      };
    });

    // const getTaggingData = async () => {
    //   loading.value = true;
    //   const { data } = await ApiBase.getTaggingData({
    //     short_key: ["settlement_document_source"],
    //   });
    //   loading.value = false;
    //   if (data.code == 0) {
    //     options.value.invoiceScourceOptions =
    //       data.data.settlement_document_source.items;
    //     if (data.data.settlement_document_source.items.length > 0) {
    //       options.value.invoiceScource =
    //         data.data.settlement_document_source.items[0].value;
    //       filterChange(options.value.invoiceScource);
    //     }
    //   }
    // };

    const filterChange = (e) => {
      filterRef.value.getSourceDocumentType(e);
    };

    const isCanExport = computed((): boolean => {
      return checkPermissionFlag({ auth: ["export"] });
    });

    const getList = async () => {
      loading.value = true;
      const { data } = await ApiMerchantCreditNotes.getList(
        setCommonFilter.value
      );
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        tableData.value.forEach((item) => {
          item.downloadDisabled = false;
        });
        total.value = data.data.total;
      }
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedInvoice.value = ids;
    };

    const handlePreview = async (item) => {
      let name = item.file_path;
      item.previewDisabled = true;
      const { data } = await ApiBase.getFulfillmentFile({
        id: item.credit_note_file,
      });
      item.previewDisabled = false;
      commonPreviewFile(childWin, name, data);
    };

    const handleInvoice = (item) => {
      options.value.invoiceId = item.id as string;
    };

    const handleDownload = async (item) => {
      const name = item.__show.credit_note_file;
      item.downloadDisabled = true;
      const data = await ApiBase.getFulfillmentFile({
        id: item.credit_note_file,
      });
      item.downloadDisabled = false;
      commonExportFile(data, name);
    };

    const handleDelete = (item) => {
      Swal.fire({
        title: t("orderRequest.deleteTip"),
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t("common.okDeleteIt"),
        cancelButtonText: t("common.nopeClose"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          item.deleteDisabled = true;
          const { data } = await ApiMerchantCreditNotes.delete({
            credit_note_id: item.id,
          });
          item.deleteDisabled = false;
          if (data.code === 0) {
            updateList();
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const handleReset = () => {
      options.value.invoiceId = "";
    };

    // const filterChangeStatus = (type, flag = true) => {
    //   let params = {};
    //   if (type === "all") {
    //     if (flag) {
    //       disabledExportAll.value = true;
    //       params = {
    //         ...setCommonFilter.value,
    //         id: [],
    //       };
    //     } else {
    //       disabledExportAll.value = false;
    //     }
    //   } else if (type === "selected") {
    //     if (flag) {
    //       if (checkedInvoice.value.length > 0) {
    //         params = {
    //           ...setCommonFilter.value,
    //           id: checkedInvoice.value,
    //         };
    //         disabledExportSelected.value = true;
    //       } else {
    //         return false;
    //       }
    //     } else {
    //       disabledExportSelected.value = false;
    //     }
    //   }
    //   if (flag) {
    //     return params;
    //   }
    // };

    // const filesExport = async (type = "all") => {
    //   let idParams = filterChangeStatus(type);
    //   const data = await Api.exportInvoice({
    //     ...idParams,
    //   });
    //   filterChangeStatus(type, false);
    //   commonExportFile(data);
    // };

    // const fileExport = async (id, item) => {
    //   item.downloadDisabled = true;
    //   const data = await ApiDocumentCenter.exportInvoice({
    //     id: [id],
    //   });
    //   item.downloadDisabled = false;
    //   commonExportFile(data);
    // };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item.includes(options.value.filter_date)) {
            const isStart = item.includes("_start") ? true : false,
              date = isStart
                ? commonChangeFilterAddDate(val[item])
                : commonChangeFilterAddDate(val[item], 1);
            filterArr.push({
              field: options.value.filter_date,
              value: date,
              condition: isStart ? "gteq" : "lt",
            });
          } else if (item.includes("created_at")) {
            const isStart = item.includes("_start") ? true : false,
              date = isStart
                ? commonChangeFilterAddDate(val[item])
                : commonChangeFilterAddDate(val[item], 1);
            filterArr.push({
              field: "created_at",
              value: date,
              condition: isStart ? "gteq" : "lt",
            });
          } else if (item == "doc_code" || item == "service") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else if (item == "invoice_number") {
            filterArr.push({
              field: "invoice_number",
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      updateList();
    };

    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    const updateList = () => {
      getList();
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const debounceSearch = _.debounce(updateList, 1000);

    const searchItems = () => {
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    const init = () => {
      setModuleBCN(t, route);
      updateList();
    };

    onMounted(() => {
      init();
      // if (store.getters.getAliveList.length === 0) {
      //   init();
      // }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      checkPermissionFlag,
      substringFileName,
      formatDate,
      InvoiceStatus,
      getMerchantOrderInvoiceStatus,
      loading,
      filterRef,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      options,
      filterChange,
      isCanExport,
      disabledExportAll,
      disabledExportSelected,
      disabledExportLastWeek,
      disabledExportLastMonth,
      checkedInvoice,
      updateList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      onRowsSelectChange,
      handlePreview,
      handleInvoice,
      handleDownload,
      handleDelete,
      handleReset,
      // filesExport,
      // fileExport,
    };
  },
});
